
import EmployeeIcon from "./employeeicon";
import Triangle from "./triangle";
const CareersIntro = ({theme}) => {
    return (
        
        <div id="intro" className={` 2xl:w-5/6 md:w-5/6 m-auto flex  2xl:h-screen md:h-screen xs:h-fit  `}>
        
        <div className=" z-20 2xl:w-7/12 xs:w-full 2xl:p-1 mx-auto    h-fit">
<div className={` flex 2xl:text-9xl md:text-9xl xs:text-3xl m-auto items-top  font-thin height-fit ${theme.tailwind_text_light} `} >

Our Careers Portal!   <EmployeeIcon theme={theme} height={200}  />

      </div>
     
      <div className={ `hover:animate-shake 2xl:text-4xl md:text-3xl xs:text-xl 2xl-w-1/2 md:w-5/6 m-auto xs-w-full  font-thin text-justify   ${theme.tailwind_text_light} p-5 `}>




We're excited to have you here. At Advanced Solutions, our people are the key to our success. We offer an innovative and dynamic environment where you can thrive and make a significant impact.

Explore our job openings and find the opportunity that fits you. Whether you're starting out or looking to advance, we have roles for diverse skills and interests.


      </div>
    
  <div className="  a m-auto  w-fit   ">
        
                <Triangle theme={theme} height={100}  deg={90} />
            
                </div>
        </div>
       


  </div>
    )
}
export default CareersIntro;