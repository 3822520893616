
import SLogo from "./singleLogo";

const Footer = ({  theme}) => {
    return (
        <div className={ ` flex flex-col w-full items-center justify-center  `} >
           

<hr className={` " m-1 w-full ${theme.tailwind_text_main}  "  `}/>      
 <div className={ `" flex 2xl:flex-row xs:flex-col  ${theme.tailwind_text_dark} justify-center  " `}>
          
       
   
        <div className="flex 2xl:flex-col  xs:flex-row p-2 items-center m-auto">
              
<SLogo   text={true} width={80} theme={theme}/> 
        
        </div>
        
        
       
                

        <div className=" flex-col m-5 border-l-2 p-3 text-left  ">
         © 2024 <br></br>
         Advanced Solutions  <br></br>
         Yanbu Al Sinaiyah 9128, Saudi Arabia<br></br>
         Radwa 8 District, Al Wadi St. <br></br> 
         Building No. 3729 (Namlah Bldg.), Office No. 1

</div>


         </div>
         < a href="/tc" className="m-4 text-slate-500 no-underline" >Terms & Conditions</a>
         </div>
    );
    }

export default Footer;