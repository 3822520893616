import {  grey, red } from '@mui/material/colors';
const Theme= {
    color_main: "red-600",
    svg_color: red["A700"],
    icon_color: grey["100"],
    danger: `red`,
    
    tailwind_text_light:`text-slate-50`,
    tailwind_text_main:`text-red-600`,
    tailwind_text_dark:"text-slate-800",
    hover_top_border: `border-b-gray-600`,
   
  
    tailwind_bg_primary:`bg-slate-100`,
    tailwind_bg_secondary:"bg-slate-600"
  }
  export default Theme;