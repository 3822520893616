import { Button } from "react-bootstrap";
import { useState } from "react";

import ContactCard from "../components/contactCard";
const Register = ({auth , theme}) => {


  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [message , setMessage] = useState("Registration Form")
  const [status , setStatus] = useState("bg-slate-400")
const handleSubmit =  (e) => {
    e.preventDefault();

    fetch('http://kmail.at:400/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
       
      },
      body: JSON.stringify({  username , password , email }),
    }).then(res => res.json()).then(data => {
        console.log(data)
        if(data.status === 'success'){
            setMessage(data.message)
            setEmail('')
            setUsername('')
            setPassword('')

            setStatus('"bg-green-500"')
        }else{
            setMessage(data.message)
            setStatus("bg-orange-900")
        }
    } 
    )
    .catch(err => {
        console.log(err)
        setMessage(err.message)
        setStatus('bg-orange-900')
    })

  }

       

    return (
       
    
         <div id='intro' className='h-screen bg-slate-100 flex flex-col text-center w-screen ' >
<ContactCard  theme={theme} />
          
           {(auth && auth ==="m.kmail") ? 
       <form   onSubmit={handleSubmit} className="p-4 ">
     
       <div className={` " col-2 m-auto  shadow p-3  "  `}>
        <div className={`' shadow p-4 text-orange-100 flex flex-col justify-evenly  items-center ${status} '`} >
          {message}
          </div>
      
            <div className='flex flex-col justify-evenly   items-center'>
            <label>Username:</label>
            <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
            />
            </div>
            <div className='flex flex-col justify-evenly  items-center'>
            <label>Email:</label>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            </div>
            <div className='flex flex-col justify-evenly  items-center'>
            <label>Password:</label>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            </div>
            
            <Button  className='my-4 btn-primary col-12 rounded-0 shadow'  type="submit">Register</Button>
          </div>
        </form>
         :<div><a className="no-underline uppercase btn btn-danger p-5 m-5" href="login ">login to admin area</a></div> }
        </div>
        
      
    );
    }

    export default Register;