import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import Button from "react-bootstrap/Button";

//import { useHistory } from 'react-router-dom';

import Cookies from 'js-cookie';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message , setMessage] = useState("Login to access secure area")
  const [messagestatus , setMessageStatus] = useState("text-info")
  const [loading , setLoading] = useState(false)


  //const history = useHistory();

    const handleSubmit = async (e) => {
    e.preventDefault();
      try{
        setLoading(true);
    const response = await fetch('http://kmail.at:4000/login', {
  
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  username , password }),
    });

    if (!response.ok) {
      console.log('Login failed');
      setMessage('Login failed! Please try again.');
      setMessageStatus('text-danger')
      setPassword('');
      setUsername('');
      setLoading(false);
      return;

    }else{
    
    const result = await response.json();
    console.log(result);
    result.username ? Cookies.set('token', result.username) : console.log('Login failed');
    console.log(Cookies.get('token'));
   setLoading(false);
    window.location.reload();
    } 
    }
    catch(err){
       setMessage(err.message);
       setLoading(false);
    }
  };
    
   
useEffect(() => {
    console.log('useEffect');

    
}, [loading])
       
        
    

  return (
 
<div id='intro' className='flex 2xl:h-screen mx-auto w-full backdrop-blur-2xl 2xl:w-10/12  md:w-full' >
       
 
  
      
       
 
   
  
      <form onSubmit={handleSubmit} className='mx-auto h-96 my-24 border p-5 rounded bg-white' >
        ADV. SOL. Dashboard
      {loading && <CircularProgress className='block'  />}
        
      <div className={ messagestatus}>{message}</div>
        <div className='flex flex-col justify-evenly  items-center '>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className='flex flex-col justify-evenly  items-center'>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button  className='px-5 m-4 btn-secondary'  type="submit">Login</Button>
        </div>
        
         <a  href='/register'>Register or contact Admin</a> 
      </form>
      
    </div>
         
  
    
    
       

    
    
  );
};

export default Login;
