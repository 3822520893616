import React from 'react';
import ContactCard from '../components/contactCard';

const PrivacyPolicy = ({theme}) => {
  return (
    <div id="intro" className={` ${theme.tailwind_bg_primary}`}>
    <div  className='2xl:w-4/12 xs:w-full  m-auto p-5 text-justify' >
      <h1>Privacy Policy</h1>

      <p>Last updated: June 19, 2024</p>

      <h2>1. Introduction</h2>
      <p>Welcome to Advanced Solutions For Machinery & Equipment Co. Ltd. ("Company", "we", "our", "us"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.advantions.com ("Website"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.</p>

      <h2>2. Information We Collect</h2>
      <p>We may collect information about you in a variety of ways. The information we may collect on the Website includes:</p>
      <ul>
        <li><strong>Personal Data:</strong> Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Website or when you choose to participate in various activities related to the Website, such as online chat and message boards.</li>
        <li><strong>Derivative Data:</strong> Information our servers automatically collect when you access the Website, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Website.</li>
        {

         //<li><strong>Financial Data:</strong> Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Website.</li> 
         }
      </ul>

      <h2>3. Use of Your Information</h2>
      <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Website to:</p>
      <ul>
        <li>Create and manage your account.</li>
        <li>Process your transactions.</li>
        <li>Provide you with customer support.</li>
        <li>Respond to your inquiries and requests.</li>
        <li>Send you updates, newsletters, and promotional materials.</li>
        <li>Monitor and analyze usage and trends to improve your experience with the Website.</li>
        <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
        <li>Assist law enforcement and respond to subpoenas.</li>
      </ul>

      <h2>4. Disclosure of Your Information</h2>
      <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
      <ul>
        <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</li>
        <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
      </ul>

      <h2>5. Cookies and Tracking Technologies</h2>
      <p>We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Website to help customize the Website and improve your experience. When you access the Website, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Website.</p>

      <h2>6. Security of Your Information</h2>
      <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>

      <h2>7. Policy for Children</h2>
      <p>We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe we might have any information from or about a child under 13, please contact us at the details provided below.</p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</p>

      <h2>9. Contact Us</h2>
      <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
      
      <ContactCard theme={theme} />
    </div>
    </div>  
  );
};

export default PrivacyPolicy;
