import Json from "./fetchJson"
import { useState } from "react";

import ServiceCard from "./servicecard";

const  SecondaryServiceFetcher = ({theme}) => {
      const services = Json({url: "../services.json"})
    const [details, setDetails] = useState(true)

    
    return(
        <div className="">
        
        {
            services && services[0]["cats"].map((service) => {
                console.log(service)
                return (
                    <div className={ `  `}>
                    <div className={ ` flex justify-center mx-auto p-2 grid grid-cols-12 col-span-12     w-10/12 ${theme.tailwind_text_dark}  font-thin   `} >
                    <div className={ ` flex  flex-col h-auto 2xl:text-3xl xs:text-md  col-span-12 2xl:px-24 2xl:mx-48  md:px-8 md:mx-4 xs:px-8      items-center   text-justify rounded-xl   border-x-8  border-${theme.color_main}  w-1/1 } `} >   {service.description} 
                    
                    </div>
                

                    {
                        details && service.prods && service.prods.map((prod) => {
                            return (
                                <ServiceCard  service={prod} theme ={theme}/>
                            )
                        })


                    }
                    </div></div>
                )
            })
        }

      </div>
    )
}
export default SecondaryServiceFetcher;