import Triangle from "./triangle";
const ServiceIntro = ({theme}) => {
    function    scroll(e){
        console.log(e.target)
      e.target.setAttribute("stroke" , "green-500")
    }
  
    return (
<div className={ `z-20 flex flex-col 2xl:h-screen md:h-screen xs:h-auto  text-center m-auto  mt-5 2xl:w-5/6  pt-5`}  >


        <div className= {` ${theme.tailwind_text_light} 2xl:text-5xl md:text-7xl xs:text-5xl  `} >The all in one Service for</div> 
        <div className={`2xl:text-9xl md:text-5xl xs:text-2xl ${theme.tailwind_text_light} `}>Machinery & Equipments</div> 

  
 <Triangle theme={theme} height={50} animate={true} onClick={scroll} />
</div>   
);
    }
export default ServiceIntro;