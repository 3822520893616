
import Frame from "../components/frame"
import ContactCard from "../components/contactCard"
import Theme from "../components/theme"
import Triangle from "../components/triangle"

import ContactIntro from "../components/contact_intro"
import ContactSection from "../components/contact_section"
const NContact = () => {
  
    return (
        <Frame  children={[
            <ContactIntro theme={Theme}  />, 
           
            
             
           
            <ContactSection theme={Theme} />,
       

        <ContactCard theme={Theme}  button={false} />,
              
            
            
           
        ]}
        />
          
    )
}
export default NContact