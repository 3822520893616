
import World from "../components/world"

import Captcha from "./contact_form_with_captcha"
const ContactSection = ({theme}) => {
    return (
        <div className={`  `}>

           
       <div className={ `flex 2xl:flex-row w-8/12 m-auto md:flex-row  xs:flex-col  2xl:p-5 xs:p-1  `}  >
       
       <World theme={theme} color={"white"}    style="hover:fill-pink-500   "/>
       { // <ContactForm theme={theme}  />
<Captcha theme={theme} />
}


 </div>   
         
         </   div>
         
    
         
                
                 
                 
              
     )  
}
export default ContactSection