import React from 'react';
import ContactCard from '../components/contactCard';

const TermsAndConditions = ({theme}) => {
  return (
    <div id="intro" className={` ${theme.tailwind_bg_primary}`}>
      
    <div  className={`2xl:w-4/12 xs:w-full  m-auto p-5 text-justify ${theme.tailwind_bg_primary}`}  >
        <h1>Terms and Conditions of Use</h1>
            
            <h2>1. Introduction</h2>
            <p>Welcome to the official digital portal of Advanced Solutions For Machinery & Equipment Co. Ltd. (hereinafter referred to as "the Company"). By accessing or utilizing our website, which is hosted at www.advantions.com (hereafter referred to as "the Website"), you explicitly agree to comply with and be legally bound by the following Terms and Conditions of Use (hereafter referred to as "Terms"). Should you find yourself in disagreement with any part of these Terms, we respectfully request that you refrain from using our Website.</p>
            
            <h2>2. Definitions</h2>
            <ul>
                <li><strong>Company:</strong> Refers to Advanced Solutions For Machinery & Equipment Co. Ltd.</li>
                <li><strong>Website:</strong> Refers to www.advantions.com, along with all its associated pages and features.</li>
                <li><strong>User:</strong> Refers to any individual or entity that accesses or utilizes the Website in any manner.</li>
            </ul>

            <h2>3. Services</h2>
            <p>The Company is dedicated to providing comprehensive solutions in electrical engineering, instrumentation, automation, and control systems tailored for diverse industrial sectors such as Oil & Gas, Petro-chemical, Power & Water, among others. Detailed descriptions of our services, including specifications, applications, and support details, are available on the Website for your review.</p>

            <h2>4. Use of the Website</h2>
            <ul>
                <li><strong>Eligibility:</strong> To access and use the Website, you must be at least 18 years of age.</li>
                <li><strong>Account Security:</strong> It is your responsibility to maintain the confidentiality of your account credentials and to be fully accountable for all activities that occur under your account.</li>
                <li><strong>Prohibited Activities:</strong> Users are strictly prohibited from using the Website for any unlawful activities or in ways that may harm the Company or any third parties. This includes, but is not limited to, transmitting viruses, hacking, or attempting to access unauthorized information.</li>
            </ul>

            <h2>5. Intellectual Property</h2>
            <p>All content present on the Website, including but not limited to text, graphics, logos, images, and software, is the exclusive property of the Company and is protected under international copyright laws. Unauthorized reproduction, distribution, or use of any content from the Website is strictly prohibited and may result in legal consequences. read our   </p>

            <h2>6. Privacy and Cookies</h2>
            <p>Your use of the Website is also governed by our Privacy Policy, which is an integral part of these Terms. We encourage you to review our Privacy Policy to understand how we collect, use, and protect your information. The Website utilizes cookies to enhance user experience, analyze site usage, and personalize content. By continuing to use the Website, you consent to the use of cookies in accordance with our Privacy Policy. Cookies are small data files stored on your device that help us in delivering a tailored and efficient online experience <a href='/privacy-policy'>Privacy Policy</a> .</p>

            <h2>7. Disclaimers</h2>
            <ul>
                <li><strong>No Warranty:</strong> The Company provides the Website on an "as is" basis without any warranties, whether express or implied, regarding the operation of the Website or the accuracy, completeness, or reliability of any content, information, or materials contained therein.</li>
                <li><strong>Limitation of Liability:</strong> The Company shall not be held liable for any direct, indirect, incidental, punitive, or consequential damages arising out of or in connection with the use or inability to use the Website, including any damages resulting from errors, omissions, interruptions, or loss of data.</li>
            </ul>

            <h2>8. Indemnification</h2>
            <p>Users agree to indemnify, defend, and hold harmless the Company, its affiliates, and their respective officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, and expenses, including reasonable attorneys' fees, arising out of or related to their use of the Website or any violation of these Terms.</p>

            <h2>9. Governing Law</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of the Kingdom of Saudi Arabia, without regard to its conflict of law principles. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Saudi Arabia.</p>

            <h2>10. Changes to the Terms</h2>
            <p>The Company reserves the right to modify these Terms at any time. Any changes to the Terms will be effective immediately upon posting on the Website. Your continued use of the Website following the posting of any changes constitutes your acceptance of the modified Terms.</p>

            <h2>11. Contact Information</h2>
      <p>For any questions or concerns regarding these Terms, please contact us at: <br></br><a href='/contact'>contact form</a></p>
    <ContactCard theme={theme}/>

      <p>These Terms constitute the entire agreement between the User and the Company regarding the use of the Website.</p>
    </div>
    </div>
  );
};

export default TermsAndConditions;
