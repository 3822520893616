import Slogan from "./slogan";
const Intro = ({theme}) => {
    return (
        
        <div id="intro" className={`  flex  2xl:h-screen md:h-screen xs:h-auto  grid 2xl:grid-cols-12  sm:grid-cols-1 2xl:gap-1 md-gap-1 2xl:w-5/6 xs:w-12/12  2xl:p-5 md:p-0 xs:p-2 m-auto    `}>
        
       
  <Slogan theme={{
        tailwind_text_main:theme.tailwind_text_main,
        tailwind_text_light:theme.tailwind_text_light,
        tailwind_text_dark:theme.tailwind_text_dark,
        svg_color: "white"  , 
        icon_color:"red-500"

    
  }}  height={200} text={"Your E, I & CS Systems Integration Partner"} />
  
        <div className={`  z-20 mx-1 my-5   2xl:py-3 md:p-5  xs-p-1 2xl:col-span-5 md:col-span-12 xs:col-span-9   hover:text-slate-800  ${theme.tailwind_text_dark}  text-xl  md:text-lg   `} >
       
    



   
          
        </div>


  </div>
    )
}
export default Intro;