import Frame from "../components/frame"


import Theme from "../components/theme"
import Login from "../components/login"

const Nlogin = () => {
    return (
        <Frame coloring={Theme} children={[
            
            <Login theme={Theme}  /> ,
        
        ]}
        />
          
    )
}
export default Nlogin