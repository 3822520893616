
import Frame from "../components/frame"

import Theme from "../components/theme"

import CareersIntro from "../components/careers_intro"

import ContactCard from "../components/contactCard"
import CareerSection2 from "../components/career_section_2"
const NCareers = () => {
  
    return (
        <Frame  children={[
            
            <CareersIntro theme={Theme} />,
             <CareerSection2 theme={Theme} />,
            <ContactCard theme={Theme} />,
           
              
              
            
            
           
        ]}
        />
          
    )
}
export default NCareers