import { Stack } from "react-bootstrap";
import World from "../components/world";
import { grey } from "@mui/material/colors";

const AccessDenied = ({theme}) => {
    return (
        <div id='intro' className={ ` " text-center p-20 ${theme.tailwind_bg_primary} h-auto " `}>
        
        <h1>Secure Area </h1>
        <p>You must be logged in to view this page.

        </p>
        <Stack direction="horizontal" gap={3} className="justify-center">  
        <a href="/login" className="btn btn-success col-1 ">Login</a>
        <a href='/register' className="btn btn-secondary col-1">Register</a>
       
        </Stack>
        <World color={grey[500]}  style="hover:fill-green-600"/>
        </div>
    );
    }
    export default AccessDenied;