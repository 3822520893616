import { Icon } from "@mui/material"
import Frame from "../components/frame"
import MainServiceFetcher from "../components/mainserviceFetcher"
import Theme from "../components/theme"
import IconSet from "../components/iconset"
import ServiceIntro from "../components/service_intro"
import SecondaryServiceFetcher from "../components/secondary_services"
const NServices = () => {
  

  
    return (
        <Frame  children={[
            <ServiceIntro theme={Theme} />,

            <MainServiceFetcher theme={Theme}  /> ,
            <SecondaryServiceFetcher theme={Theme}  /> ,
            
            
           
        ]}
        />
          
    )
}
export default NServices