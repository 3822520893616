import {CardContent, CardHeader,Avatar } from "@mui/material"

const StartpageCard = ({ title, description,  avatartext , theme }) => {

    return (
    
 
        <div className="border transition-all duration-700 text-2xl 2xl:px-2  md:px-1  2xl:py-5 xs:py-5 md:py-5 hover:shadow-2xl  "  variant="outlined"  >
            <CardHeader title={title}
            
              avatar={
          <Avatar sx={{ bgcolor: theme.error}} aria-label="recipe">
            
            {avatartext}
          </Avatar>
          
        }
        
            />
          
            <CardContent>
            
           
                <div className={ ` 2xl:p-4     md:p-1 text-sm  xs:p-4  ${theme.tailwind_text_dark} text-justify `}>
                   {description}
                </div>
            </CardContent>
    
        
        
            
              
            </div>
          

    );
    }

export default StartpageCard;