
import React from "react"
import Banner from "./banner"





const Navbutton = ({text , link , id , auth , protect , theme}) =>{
const [info , setInfo] = React.useState(<></>)
const showinfo = (e) =>{
   
    e.preventDefault()  
    const intro = document.getElementById('intro')
    

    intro.classList.add('transparent')
    setInfo(<Banner menu_id={id} theme={theme} />)
    //setInfo(<a className="text-9xl info">hello</a>)
     //setInfo(<div className="info text-9xl" >{id}</div>)
}
const hideinfo = (e) =>{

e.preventDefault()
 const intro = document.getElementById('intro')

    intro.classList.remove('transparent') 
   
setInfo(null)
}
const hover_border_button_color = "hover:border-red-600"

    return(

        
    <li className={ ` font-bold transition-color duration-700 flex 2xl:w-full md:w-full xs:h-auto  hover:border-0 ${hover_border_button_color}  hover:border-b-8  ` } >
        
        {(!auth  && protect ===true) ? <></> :
               <a className={ ` w-auto flex items-center   uppercase  2xl:px-5 md:px-3 ${theme.tailwind_text_dark}   2xl:text-lg  md:text-sm sm:text-sm text-center no-underline `}  href= {link} 

   
   onMouseEnter={
    //showinfo
    console.log('show')
   }
  onMouseLeave={
//hideinfo
console.log('hide')
  }
   >
    {text}
{info}

 
    </a> 
        }
 

</li>
    
    )
} 

export default Navbutton
