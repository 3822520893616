import CountUp from 'react-countup';
const Counter = ({count , title, suffix , theme}) => {
    return ( 
        <div className={ `2xl:border-0 xs:border  transition-shadow duration-700  flex flex-col items-center justify-center  2xl:text-5xl  ${theme.tailwind_text_dark}    border-x text-center  2xl:my-3 2xl:px-5 md:p-4 xs:p-2 w-full  border-x-1 border-x-slate-300 hover:shadow-2xl 2xl:h-96 md:h-96 xs:h-96  xs:m-2   `} >
            
            <CountUp  className='2xl:m-5  md:m-1 md:text-5xl xs:text-3xl  ' end={count} duration={5} suffix={suffix} />
            <div className={ `2xl:text-3xl md:text-4xl xs:text-3xl p-3    font-thin ${theme.tailwind_text_main}`} >{title}</div>
        </div>
     );
}
export default Counter;