
import Navbar from "./navbar";

const Tron = ({auth , theme}) => {
    return (
      <div className={ `      `} >

  

 
          <div className=" ">
            <Navbar theme={theme} auth={auth} />
            </div> 
        </div>
    );
    }
    export default Tron;