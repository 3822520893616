
import IconSet from "./iconset";
import Footer from "./footer";
import Theme from "./theme";



const Frame = ({ children=[]  , auth  }) => {
     
    return (
        <div id="frame" className={ `  2xl:h-screen  md:h-screen xs:h-full  xs:w-full m-auto    `} >
            ,
         <div className="absolute  w-full backdrop-blur-2xl ">
{children}
<Footer auth={auth} theme={Theme}/>
         </div>
        
        { // <IconSet theme={Theme} />
}
         
        

          <div className=" absolute top-0 right-0  -z-1  " >
  {// <Percentage theme={Theme} height={1500} />
  }  </div>
        </div>
    );
    }
    export default Frame;
