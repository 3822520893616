import Slogan from "./slogan";
const IntroSection2 = ({theme}) => {
    return (
        
        <div id="intro" className={`flex  2xl:h-screen md:h-screen xs:h-auto  grid 2xl:grid-cols-12 md:grid-cols-12  xs:grid-cols-12 2xl:gap-1 md-gap-1 2xl:w-5/6 md:w-full xs:w-full  2xl:p-3 md:p-0 xs:p-2 m-auto     `}>
        
  
        <div className={`  z-20 mx-1 my-2   2xl:py-3 md:p-5  xs-p-1 2xl:col-span-8 md:col-span-12 xs:col-span-12     ${theme.tailwind_text_dark}  text-xl  md:text-lg   `} >
    <div className=" p-5 trasition-background duration-700 hover:bg-slate-50  border hover:shadow-2xl text-justify     " >

     <div className={ `  text-center flex flex-col 2xl:text-5xl  xs:text-xl   ${theme.tailwind_text_dark}  font-bold `} >
  
      Our Aim 
</div>
<div className={ `  2xl:w-full xs:w-full   m-auto text-2xl font-thin text-justify   ${theme.tailwind_text_dark} ` } >
Advanced solutions is a Saudi based organization founded in 2019, our aim is to be a well‐known company in the region by our commitment, high standards, efficient services and work implementation in the premises and sites of our customers in Oil & Gas and Heavy Industry sectors at YANBU & JUBAIL.
<ul className="list-disc m-4">
<li className="">Commitment to a contract with an integrated team, not with multiple involved parties.</li>
<li>Commitment to maximize the local content.</li>
<li>High care of health and safety – zero tolerance of accidents.</li>
<li>Implementation of appropriate quality standards.</li>
<li>Certainty of schedule and completion date.</li>
<li>Project preliminary scope of work, BOQ, technical solutions, and budgetary proposals preparation.</li>
<li>Project development including; CDP and FEED packages preparation.</li>
<li>General construction design & engineering services including; 30%, 60%, 90% & 100% DEP (IFC) as well as red marked up and as built documentations.</li>
<li>Construction and vendor materials procurement & supply.</li>
<li>Site installation & construction including; MOS, project planning.</li>
<li>Quality & safety management including PQP, ITP, HSEP, JLA, etc.</li>
<li>Testing & commissioning support including SAT and procedures.</li>


</ul>

</div>





</div>






   
          
        </div>


  </div>
    )
}
export default IntroSection2;