import { useState , useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import Slide from './slide';

const  Caro = ({theme})=> {
  const [index, setIndex] = useState(0);
const [slides , setSlides] = useState([])
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
 
  useEffect(() => {
    fetch('../caroseldata.json')
    .then(res => res.json())
    .then(data => setSlides(data[0]['slides']))
  }, [])
    console.log(slides)
  

  return (
    
    <Carousel  className='w-full  my-1 py-0' activeIndex={index} onSelect={handleSelect}>
     
 

    {
        slides && slides.map((slide , index) => {
            return(
      <Carousel.Item className=''>
       <Slide theme={theme}  key={index}  slide={slide} />
        
      </Carousel.Item>)
}
        )
    }

      
    </Carousel>

  );
}

export default Caro;