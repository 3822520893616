import Talent from "./talent";

const CareerSection2 = ({theme}) => {  
    return (
        <div className={ `  2xl:p-24 xs:p-3`}>
            <div className={ `  2xl:w-7/12  m-auto 2xl:p-24 text-justify  `} >
        <div className={ `${theme.tailwind_text_main}  ${theme.tailwind_bg_primary} text-9xl fonter  h-48 w-48  rounded-full   `}>
             <div className="float-start ">
            Talents
            <Talent theme={theme} height={200} />
            </div>
           

        </div >
        <div className="text-3xl font-thin">
           Didn't find a suitable position? No problem! If you're passionate and motivated, send us an initiative application. We're always looking for talented individuals to join our team.

Join us and be part of a team that values innovation, collaboration, and growth. Your exciting career journey starts here!

        </div>
        </div>
        </div>
    );
    }
export default CareerSection2;