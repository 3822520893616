import Frame from "../components/frame"

import Intro from "../components/intro"
import CounterPanel from "../components/countpanel"
import Caro from "../components/carosel"
import Tailer from "../components/tailer"
import Theme from "../components/theme"
import IntroSection2 from "../components/introSection_2"
import IntroSection3 from "../components/introSection_3"
import IntroSection1 from "../components/introSection_1"
const Start = () => {
    return (
        <Frame coloring={Theme} children={[
            
            <Intro theme={Theme}  /> ,
            <IntroSection1 theme={Theme} />,
            <IntroSection2 theme={Theme} />,
            <IntroSection3 theme={Theme} />,
            <Caro   theme={Theme} />,
            <CounterPanel theme={Theme} />,
            <Tailer theme={Theme} />
        ]}
        />
          
    )
}
export default Start