
const TeamIntro = () => {
    return (
        
        <div id="intro" className={` flex flex-col  2xl:h-screen md:h-screen xs:h-screen   2xl:w-5/6 m-auto  `}>
        <div className="  text-9xl  font-mdium text-center m-auto   text-white ">Our Team</div>
  <div className=" 2xl:flex relative  md:flex  w-fit top-0  m-auto  ">
            
              
                <div className="z-20 hover:w-1/3  m-3 transition-w duration-300 hover:ease-in-out  relative w-1/2  right-48   m-auto  " >
                <img  width={300} className=" shadow-2xl   rounded-full border-3  " src="./img/person.png" alt="team " />
                </div>
                  <div className="z-20 hover:w-1/2  m-3 transition-w duration-300 absolute w-1/3 left-24      m-auto    m-3 p-2" >
                <img width={300} className=" shadow-2xl rounded-full border-3  " src="./img/person.png" alt="team " />
                </div>
                <div className="z-20 hover:w-2/3  m-3 transition-w duration-300 absolute w-3/4 2xl:right-80 xs:right-48  top-24     m-auto    m-3 p-2" >
                <img width={300} className="shadow-2xl rounded-full border-3 " src="./img/person.png" alt="team " />
                </div>
                <div className="z-20 hover:w-2/3  m-3 transition-w duration-300 absolute w-3/4 2xl:left-48 xs:right-0  top-24     m-auto    m-3 p-2" >
                <img width={300} className="shadow-2xl rounded-full border-3 " src="./img/person.png" alt="team " />
                </div>
                  <div className="z-20 hover:w-1/3  m-3 transition-w duration-300 absolute w-2/12 left-80  top-8      m-auto    m-3 p-2" >
                <img width={300} className="shadow-2xl rounded-full border-3 " src="./img/person.png" alt="team " />
                </div>
                <div className="z-20 hover:w-1/3  m-3 transition-w duration-300 absolute w-5/12 left-48 top-48      m-auto    m-3 p-2" >
                <img width={300} className="shadow-2xl rounded-full border-3 " src="./img/person.png" alt="team " />
                </div>
                <div className="z-20 hover:w-1/3  m-3 transition-w duration-300 absolute w-1/2 left-12 top-48      m-auto    m-3 p-2" >
                <img width={300} className=" shadow-2xl   rounded-full border-3 " src="./img/person.png" alt="team " />
                </div>
            
                   
            
        </div>
     
       

            

  </div>
    )
}
export default TeamIntro;