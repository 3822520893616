const Dashboard = ({auth}) => {
  return (
    <div id="intro" className=" m-auto h-svh   grid 2xl:grid-cols-12 sm:grid-cols-1 gap-1  w-full p-5 bg-slate-100 z-30 " >
      
      
      
    
      <div className='text-5xl  mx-auto col-span-12'>
        <div className='flex  py-8 w-full justify-center mx-auto col-span-12 '>Welcome Back {auth}</div>
        ADV. SOL. Dashboard</div>
      
  
    </div>
  );
}
export default Dashboard;