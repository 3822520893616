
import Frame from "../components/frame"

import Theme from "../components/theme"

import TeamIntro from "../components/team_intro"
import Members from "../components/teammembers_fetcher"
const NTeam = () => {
  
    return (
        <Frame  children={[
            <TeamIntro theme={Theme} />,
            <Members theme={Theme} />,
       
              
            
            
           
        ]}
        />
          
    )
}
export default NTeam