import Json from "./fetchJson"
import { useState } from "react";
import MainService from "./mainservice";
import Triangle from "./triangle";

const  MainServiceFetcher = ({theme}) => {
      const services = Json({url: "../services.json"})
   

    
    return(
        <div className={`  py-5`} >
        <div className={ ` flex flex-col h-auto  grid grid-cols-12   my-8  2xl:w-11/12 md:w-12/12 xs:w-12/12  mx-auto $`} >
            {
                services && services[1]["major_services"].map((service) => {
                    return(
                        <MainService service={service}  icon={<Triangle theme={theme} height={100} animate={false} deg={90} /> }/>
                    )
                })
            }
        </div></div>
    )
}
export default MainServiceFetcher;