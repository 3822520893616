
import DiagramIcon from "./diagramIcon";
const ProjectsIntro = ({theme}) => {
    return (
        
        <div id="intro" className={` z-30 w-10/12 m-auto flex flex-col  2xl:h-screen md:h-screen xs:h-screen   ${theme.tailwind_text_light} `}>
          <div className={ `text-9xl  font-thin `}>Projects Portal</div>
       <div className="z-30    ">
         <div className={ ` h-auto flex transition-all rounded-xl duration-700    z-30 2xl:w-3/4 md:w-full xs:w-full  2xl:p-1 xs:p-1 my-auto 2xl:ml-5 2xl:p-1 md:p-5 xs:h-96 2xl:text-3xl md:text-4xl xs:text-2xl  font-thin   `}>
        <div > Welcome to our Projects Portal! Here, we showcase a diverse array of engineering and construction projects undertaken across various industries.</div>
    
   
     <DiagramIcon style="" theme={theme} height={200} />
     </  div>


<div className="z-40">
 
<div className={` h-auto transition-shadow duration-700 hover:shadow-2xl flex float-end 2xl:w-1/2   text-5xl font-Thin 2xl:mx-8 xs:my-2 mb-2 2xl:p-2 xs:h-96 xs:m-auto items-center md:m-5 md:p-5 md:w-3/4 xs:p-5 fonter rounded-xl ${theme.tailwind_text_light}  `}>

Each project is tailored to meet the specific needs of our clients, ensuring efficient, reliable, and state-of-the-art solutions. Browse through to explore our commitment to innovation and excellence in every project we undertake.
</div>
        </div> 
 
</div>



     
      

  </div>
    )
}
export default ProjectsIntro;