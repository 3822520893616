

import {
    BrowserRouter as Router,
    Route, Routes
} from 'react-router-dom';

import AccessDenied from '../pages/accessdenied';

import Register from '../pages/register';

import  Dashboard from '../pages/dashboard';


import TermsAndConditions from '../pages/tc';
import PrivacyPolicy from '../pages/pp';


import Start from '../pages/1_start';
import NServices from '../pages/2_services';
import NContact from '../pages/5_contact';
import NLogin from '../pages/0_login';

import NCareers from '../pages/3_careers';
import NTeam from '../pages/4_team';
import NProjects from '../pages/6_projects';
import Cookies from 'js-cookie';
const MyRouter = ({ theme}) => {
    const auth = Cookies.get('token'); 
    return (
        <div className='grid grid-cols-1 gap-1'>

<Router>
<Routes>
<Route path='/' element={<Start auth={auth} /> }/>
<Route path='*' element={<AccessDenied theme={theme} auth={auth} />} />
<Route path='/login' element={auth  ? <Start   theme={theme} auth={auth} /> :<NLogin theme={theme} />}  />
<Route path='/register' element={<Register auth={auth} theme={theme}/>} />
<Route path='/projects' element={<NProjects theme ={theme} />} />
<Route path='/dashboard' element={auth ? <Dashboard auth={auth}/> : <NLogin/>} />
<Route path='/services' element={<NServices />} />
<Route path='/contact' element={<NContact theme ={theme}/>} />
<Route path='/tc' element={<TermsAndConditions theme={theme} />} />
<Route path='/privacy-policy' element={<PrivacyPolicy theme={theme} />} />
<Route path='/team' element={<NTeam/>} />
<Route path='/careers' element={<NCareers theme={theme} />} />


</Routes>
</Router>


   
   
   </div>)
}
export default MyRouter