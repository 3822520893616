const ContactIcon = ({theme , height}) => {   
    return (
       <div className="flex justify-center m-8 hover:fill-green-500">
            <svg stroke={theme.icon_color} fill="none" height={height} viewBox="0 0 24 24" >
<g id="Contact-Us-Filled">
	<path d="M1,1v17h4v4l8.5-4H22V1H1z M8,11H5V8h3V11z M13,11h-3V8h3V11z M18,11h-3V8h3V11z"/>
</g>
</svg>
        </div>
    )
}
export default ContactIcon;