
import MailIcon from "./mail";
import Theme from "./theme";

const ProjectCard = ({ project , nummer }) => {
    return (
        <div className=" card  transition-shadow duration-700 border bg-white 2xl:col-span-4 md:col-span-6 xs:col-span-12 hover:shadow-2xl">
         

            <div className=" card-body items-center m-auto ">
                <h1 className={ `rounded-full font-thin  border-b-8 w-48 h-48 p-2 m-auto text-9xl text-center justify-center ${Theme.tailwind_text_dark} `} > {`${nummer +1 }.`}</h1>
                <h5 className="card-title p-5 ">{project.ProjectName}</h5>
                <p className="card-text text-5xl text-center text-slate-900 p-1">{project.ProjectType}</p>
                <p className="card-text m-auto  text-3xl text-center text-slate-700  p-1 ">{project.ContractType}</p>
                <p className={` card-text text-5xl text-center  ${Theme.tailwind_text_main}  p-2 `}>{project.EndUser}</p>
                <p className="card-text text-xl text-center text-slate-900 p-5">{project.Description}</p>
                <div className="flex justify-end absolute bottom-0  ">
                    
                <MailIcon theme={Theme} height={50}  />
                </  div>
            </div>
        </div>
    );
}
export default ProjectCard;