const MainService = ({icon ,service}) => {
    return(
        <div className="card z-20 border rounded-xl transition-padding duration-700 h-auto  flex  flex-col justify-center     2xl:col-span-3 md:col-span-6 xs:col-span-12   px-5  m-auto  text-2xl hover:shadow-2xl  w-11/12 my-4 py-24  hover:w-full hover:rounded-xl   " >
       
        <div className="text-4xl px-2 my-4 text-center  ">{service.title}</div>
        <div className="text-justify text-sm ">{service.body}</div>
         {
            icon
        }
        </div>
    )
}
export default MainService;