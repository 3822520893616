
import Navbutton from "./navbutton"

import Cookies from 'js-cookie'    

import Json from "./fetchJson";

import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import OLogo from "./Ologo";


const Navbar = ({auth , theme}) =>{
    const data = Json({url: "../data.json"})
    console.log(data)
    const logout = () => {
        Cookies.remove('token')
        window.location.reload()
    }
    return (


     
    <div className={` bg-white/[.8] px-24 flex 2xl:left-0    my-0 py-0  2xl:justify-between  2xl:relative md:relative xs:absolute xs:top-0 z-40 `} >

               
  
        <nav class=" flex   ">
  <div class="  flex   ">
   
    <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2   justify-center  md:hidden hover:bg-gray-100  " aria-controls="navbar-default" aria-expanded="false" onMouseDown={
        (e) => {
            e.preventDefault()
            const navbar = document.getElementById('navbar-default')
            if(navbar.classList.contains('hidden')){
                navbar.classList.remove('hidden')
            }else{
                navbar.classList.add('hidden')
            }
        }
        
        

    }>
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke={theme.svg_color} stroke-linecap="" stroke-linejoin="round" stroke-width="1" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
    <div class=" hidden  md:block md:w-auto  " id="navbar-default">
      <ul class= " flex  2xl:w-full    md:p-0    md:flex-row  md:mt-0  xs:flex-col    border-t border-red-500 ">
<OLogo theme={theme} width={100} text={true} />
{
    data &&  data[0]['menus'].map((item , key) => {
        return (
            
            <Navbutton key={key} text={item.name} link ={item.url} id={item.id} auth={auth} protect={item.protected} theme={theme}/>
        )
    }

    ) 

}

 
    {


auth ? <LogoutIcon fontSize="large" className="flex m-auto " onClick={logout} /> : <a href='/login' className={ `flex m-auto ${theme.tailwind_text_dark} `} ><LoginIcon   fontSize="large"  /></a>
     }
    

      </ul>
    </div>
  </div>
</nav>

    </div>
    

    
   
    )

}
export default Navbar