
import Triangle from "./triangle";

import ContactIcon from "./contactIcon";
const ContactIntro = ({theme}) => {
    return (
        
        <div id="intro" className={`2xl:w-auto flex flex-col  2xl:h-screen md:h-screen xs:h-full   2xl:w-5/6  m-auto ${theme.tailwind_text_light}  `}>
        
 
     
<div className={`2xl:text-9xl md:text-5xl xs:text-3xl  text-center  font-thin  `} >Contact us </div>
<ContactIcon theme={{icon_color:"white"}} height={200} />
            <div className="transition-all duration-700  hover:animate-jump my-2 mx-auto 2xl:w-1/2 md:w-1/2 xs:w-full p-5 2xl:text-3xl md:text-3xl text-justify font-thin hover:shadow-2xl  rounded-2xl   ">We'd love to hear from you! Whether you have a question, need assistance, or want to share feedback, our team is here to help. Please fill out the contact form below, and we'll get back to you as soon as possible.

To ensure we connect you with the right team, don't forget to select your country on our interactive map. This helps us tailor our response to your specific needs and location.

Your voice matters to us – reach out today!
            
    <Triangle theme={theme} height={50} animate={false} />
            
            </div>
          
           

  </div>
    )
}
export default ContactIntro;