
import Counter from "./counter";
const Countpanel = ({theme}) => {
    return (
        <div className={ `grid grid-cols-12 w-full gap-3  justify-center  ${theme.tailwind_text_dark} 2xl:px-48 md:px-8  xs:px-5  `} >
            <div className={`flex col-span-12 justify-center 2xl:text-6xl md:text-6xl xs:text-3xl uppercase 2xl:border-x-4 border-x-4 border-${theme.color_main}  m-auto  items-center 2xl:w-1/2 md:w-1/2 xs:w-full rounded-xl  text-center py-5 my-5 `} >
                interesting stats
               
                </div>

           <div className="2xl:col-span-4 md:col-span-12  xs:col-span-12   ">
<Counter theme={theme} count={8} title="On Going Projects"  suffix={" Projects"}/> 
           </div>           
           <div className="2xl:col-span-4 md:col-span-12   xs:col-span-12  ">
<Counter theme={theme}  count={148000} title="Working Hours" suffix=' Hours' /> 
           </div>
            <div className="2xl:col-span-4 md:col-span-12  xs:col-span-12  ">
<Counter theme={theme}  count={86} title="Done Works"  suffix={"Projects"}/> 
           </div>
                </div>
              
    );
}
export default Countpanel;