
import Frame from "../components/frame"
import ProjectsFetcher from "../components/projectsFetcher"
import ProjectsIntro from "../components/projectsIntro"
import BasicTimeline from "../components/projectTimeline"

const NProjects = ({theme}) => {
  
    return (
        <Frame  children={[
            <ProjectsIntro theme={theme} />,  
            <BasicTimeline theme={theme} />,  
            <ProjectsFetcher theme={theme} />
           
        ]}
        />
          
    )
}
export default NProjects