import Json from "./fetchJson"
import MemberCard from "./MemberCard";

const Members =({theme}) => {
     const team = Json({url: "../members.json"})
    return (
        <div className={`w-full   `}>
            <div className="transition-all duration-700 rounded-lg hover:animate-pulse hover:shadow-2xl   my-5 mx-auto 2xl:w-1/4 md:w-1/2 xs:w-full p-3 text-3xl text-justify font-thin  ">Our team is a group of highly skilled professionals who are dedicated to providing the best service to our clients. We are committed to excellence and strive to exceed our clients' expectations.
            
 
            </div>
<div className={ ` m-auto items-center grid grid-cols-12  gap-2 w-9/12 z-10`}>
                {
                    team && team.map((member) => {
                        return (
                            <MemberCard theme={theme} member={member} />
                        )
                    })
                }
            
            </div>
        </div>
   
   
    )
}   
export default Members;