
const Slide = ({ slide , theme}) => {
    return (
        
<div className="flex  w-full  object-cover  xs:text-center  " >
    <div className={ `doco-container 2xl:${theme.tawilwind_bg_secondary} xs:bg-black/[.5] shadow`} >
    <div className={ `" flex flex-col 2xl:w-full xs:w-2/3  deco  2xl:text-6xl xs:text-xl   ${theme.tailwind_text_light} xl:right-0  xs:right-48 xs:bottom-24  " `} > {slide.title}

        <p className={ `flex  2xl:text-4xl xs:text-sm ${theme.tailwind_text_light}`}  > { slide.description}</p>
    </div>
    
    </div>

    <img className="flex justify-center  w-full object-cover  slide  " src={slide.image} alt={slide.alt} />
    
  
</div>


        
    )
    }
    export default Slide;