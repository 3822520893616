import { useState } from "react";
const Triangle = ({theme , height , deg=180} ) => {   
    const [color, setColor] = useState(theme.icon_color)
   
    const [animate, setAnimate] = useState(false)
    function changeColor(){
        setColor("black")
    }
  

    return (
       <div  onClick={changeColor}   className= {` flex justify-center   ${animate ? 'animate-bounce animate-infinite' : ''}  `} >
           <svg   className={` rotate-${deg}`} fillOpacity={0} strokeWidth={.4}  stroke={color} fill={color} width={height} height={height} viewBox="0 0 48 48" id="b" xmlns="http://www.w3.org/2000/svg"><path  d="m42.422,38.954H5.578c6.1407-10.636,12.281-21.272,18.422-31.908,6.1407,10.636,12.281,21.272,18.422,31.908Z"/></svg>
        </div>
    )
}
export default Triangle;