
import OLogo from "./Ologo";
const Slogan = ({theme , height , text="FOR MACHINERY AND EQUIPMENT CO. LTD."}) => {
    return (
        
              <div className={`   flex flex-col 2xl:p-0 md:py-2 xs:py-1 justify-center h-full  2xl:col-span-12 md:col-span-12 md:text-4xl xs:col-span-12      ${theme.tailwind_text_main}  `} >
                
       
           <OLogo  width={height} theme={theme} /> 

         
 

            <div className= { ` flex  2xl:flex-col 2xl:text-9xl  md:text-5xl xs:text-xl uppercase   font-thin  items-center  text-center justify-center ${theme.tailwind_text_light}  `} >
                <div className={ ` " font-bold  tracking-tighter " `}>Advanced</div><div className={ `tracking-wider ${theme.tailwind_text_ligh} `} >Solutions</div> 
                </div>
  
            <div className={`  "flex  2xl:text-3xl md:text-xl xs:text-xl   ${theme.tailwind_text_light}   text-center   tracking-wide  " `} >
                {text}  
                </div>
             
        </div>)
}
export default Slogan;
        
