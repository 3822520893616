import Slogan from "./slogan";
const IntroSection3 = ({theme}) => {
    return (
        
        <div id="intro" className={`flex  2xl:h-screen md:h-screen xs:h-auto  grid 2xl:grid-cols-12  sm:grid-cols-1 2xl:gap-1 md-gap-1 2xl:w-5/6 xs:w-12/12  2xl:p-5 md:p-0 xs:p-2 m-auto    `}>
        
       
  
        <div className={`  z-20 mx-1 my-5   2xl:py-3 md:p-5  xs-p-1 2xl:col-span-12 md:col-span-12 xs:col-span-9   hover:text-slate-800  ${theme.tailwind_text_dark}  text-xl  md:text-lg   `} >
       
    <div className=" border p-3 trasition-background duration-700 hover:bg-slate-50   hover:shadow-2xl text-justify   " >



     

     <div className={ `text-center flex flex-col 2xl:text-5xl  xs:text-xl   ${theme.tailwind_text_dark}  font-bold `} >
  
      Why<br></br> ADVANCED <span className="font-thin tracking-wider text-slate-300">SOLUTIONS</span> ... ?
</div>
<div className={ ` p-5 2xl:w-auto xs:w-full   m-auto text-2xl font-thin ${theme.tailwind_text_dark} ` } >
Being strategically located in JUBAIL & YANBU cities closed to the industrial project zones and sites has given us the privilege to provide an expedited engineering, construction and maintenance services as well as client support at short notice and consequently add extra service for them by saving both time and money, the thing which is playing an effective role in reducing time off of a project and release our clients from dependency on resources from outside of Yanbu industrial area and KSA as well.
<ul className="list-disc m-4">
<li className="">We are local company but with world‐class quality management standards which can fully satisfy our clients.</li>
<li>A group of giant robots playing a game of soccer, intense, dynamic, high detail, 3D, stylized, futuristic, metallic, robots, absurd, fantastic, wide angled, overhead view, colorful, funControl system (DCS, ESD, PLC, ATG, VMS, SCADA, and any special automation, etc</li>
<li>ADVANCED SOLUTIONS provides a wide range of services in process instrumentation & control system, advanced industrial software’s, process automation and process electrical monitoring & control and process safety systems.</li>
<li>ADVANCED SOLUTIONS offers a fully turnkey solutions for electrical, instrumentation, fire & gas, automation and control systems including design, engineering, quality, safety, construction and planning management.</li>
<li>ADVANTIONS offers a fully turnkey solutions for electrical, instrumentation, fire & gas, automation and control systems including those needed civil and mechanical related works.</li>


</ul>
</div>





</div>






   
          
        </div>


  </div>
    )
}
export default IntroSection3;