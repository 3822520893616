import Slogan from "./slogan";
const IntroSection1 = ({theme}) => {
    return (
        
        <div id="intro" className={` items-end justify-right flex  2xl:h-auto md:h-screen xs:h-auto  grid 2xl:grid-cols-12  xs:grid-cols-12 2xl:gap-1 md-gap-1 2xl:w-5/6 xs:w-12/12  2xl:p-3 md:p-0 xs:p-2 m-auto     `}>
        <div className="2xl:col-span-8">   </div>
  
        <div className=" float-end items-end trasition-background duration-700 bg-slate-50  p-5   text-justify    2xl:col-span-4  md:col-span-6 xs:col-span-12 " >


     

     <div className={ ` p-5 text-center flex flex-col 2xl:text-5xl  xs:text-xl   ${theme.tailwind_text_dark}  font-bold  `} >
  
      ADVANCED <span className={` font-thin tracking-wider ${theme.tailwind_text_main }  `}>SOLUTIONS</span> is...
</div>
<div className={ ` 2xl:w-auto xs:w-full   m-auto text-4xl font-thin    ${theme.tailwind_text_dark} ` } >
A leading Solutions Provider of the electrical, instrumentation, automation & control system solutions in the Kingdom of Saudi Arabia.

</div>





</div>


  </div>
    )
}
export default IntroSection1;