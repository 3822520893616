import Json from "./fetchJson"

import ProjectCard from "./projectCard";

const  ProjectsFetcher = ({theme}) => {
      const Projects = Json({url: "../projects.json"})
   
 
    return(
        <div className={`   `} >
        <div className={`py-8 grid grid-cols-12 w-10/12 m-auto gap-5 `} >
           
            {
                Projects && Projects.map((project , key) => {
                    return(
                        <ProjectCard key={key} project={project } nummer={key} />
                    )
                })
            }
        </div>
        </div>
    )
}
export default ProjectsFetcher;